import React, { useState } from "react"
import { graphql } from "gatsby"
import Contact from '../components/pages/Contact'

const lang = 'en'

const ContactUs = ({ data, pageContext }) => {
  //get seo data
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_en,
    titleSeo: seoDataJson.title_en,
    description: seoDataJson.description_en,
    alternateLanguage: seoDataJson.alternateLanguage_en,
    alternateUrl: seoDataJson.alternateUrl_en,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "CONTACT-US"
  })

  return (
    <Contact
      lang={lang}
      seoData={seoData}
      urls={resultUrls}
      pageContext={pageContext}
    />
  )
}

export const pageQuery = graphql`
  query contactUsEnQuery {
    seoDataJson(name: { eq: "contact-us" }) {
      url_en
      title_en
      description_en
      alternateLanguage_en
      alternateUrl_en
    }
  }
`

export default ContactUs
